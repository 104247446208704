.button-primary {
    background: var(--clr-primary);
    color: white;

    &:hover,
    &:focus {
        background: var(--clr-primary-dark);
    }

    &:disabled {
        background: var(--clr-primary-disabled);
    }
}