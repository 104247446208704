.button {
	display: block;
	width: fit-content;
	line-height: normal;
	font-size: inherit;
	padding: .7em 1.8em;
	border: 0;
	border-radius: var(--radius-input);
	cursor: pointer;
	outline: 0;
}
