.add-permit {
    padding: 4.5em 0;

    .add-permit-body {
        width: 100%;
        max-width: 700px;
    }

    .page-title {
        margin-top: 0;
    }
}

@media(min-width: 600px) {

	.add-permit {
		background: linear-gradient(90deg, var(--clr-bg-body) 85%, rgba(0,0,0, .05) 40%);
    }
}
