.text-area {
    .textarea-field {
        display: block;
        font-family: inherit;
        font-size: inherit;
        width: 100%;
        padding: var(--padding-input-y) var(--padding-input-x);
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        border: 1px solid rgb(158, 158, 158);
        border-radius: var(--radius-input);
        outline: none;
        resize: none;
        line-height: 1.4;
    }
    
    .textarea-field:focus {
        border: 1px solid #00ADFF;
    }
}