.location-form {
	display: flex;
    flex-direction: column;
	justify-content: center;
	gap: 2em;

    .left, .right {
		display: flex;
		flex-direction: column;
        gap: 2em;
	}

    .right {
		gap: 2em;
	}

    .location-form-description {
        border-radius: 5px;
		box-shadow: 0 1px 4px rgba(0, 0, 0, .15);
    }

    .map-input {
        border-radius: 5px;
        box-shadow: 0 1px 4px rgba(0,0,0,.15);
    }
    
    .location-form-position .error {
        text-align: center;
    }
}

@media(min-width: 900px) {

    .location-form {
		flex-direction: row;

		.left {
			width: 35%;
		}

		.right {
			width: 65%;
		}

        .button-bar {
            justify-content: end;
        }
    }
}