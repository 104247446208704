.button-success {
    font-size: 1.5rem;
    background: #00A361;
    color: white;
    border: 2px solid #00A361;

    &:hover,
    &:focus {
        background: #008b54;
    }

    &:active {
        color: #00A361;
        background: white;
        border: 2px solid #00A361;
    }

    &:disabled { 
        background: #8ED9B8; 
        border-color: #8ED9B8; 
    }
}