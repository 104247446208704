.map-input {
	position: relative;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: var(--radius-input);
	overflow: hidden;

	.import-geojson {
		position: absolute;
		left: 1em;
		bottom: 1em;
		z-index: 999;
	}
}