.login-form {
    display: flex;
    flex-direction: column;
    width: 25em;
    background: #FFF;
    border-radius: 5px;
    padding: 1.5em;
    box-shadow: 0 0px 20px rgba(0, 0, 0, .15);


    .login-form-title {
        text-align: center;
        color: var(--clr-primary);
        font-size: 2rem;
        margin-top: 0;
        padding-bottom: .8em;
        border-bottom: 1px solid var(--clr-primary);
    }

    .button-success {
        width: 100%;
        margin-top: 2em;
    }
}