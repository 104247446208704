.location-map {
	position: relative;
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, .15);

	.location-directions {
		position: absolute;
		font-size: 1rem;
		left: 10px;
		bottom: 24px;
		color: var(--clr-primary-dark);
		border: 1px solid;
		box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
	}
}