.location-card {
	width: 100%;
	background: #FFF;
	box-shadow: 0 1px 4px rgba(0,0,0,.15);

	.location-card-image {
		height: 340px;
		border-radius: 5px 5px 0 0;
		overflow: hidden;
	}

	.location-card-title {
		text-align: center;
		font-size: 2rem;
		margin: .75em;
	}

    .location-card-body {
		display: flex;
		flex-direction: column;
		gap: 1.5em;
		min-height: 250px;
        padding: 0 1.5em 3em;
    }

	.location-card-footer {
		border-radius: 0 0 5px 5px;
	}

	.location-species-list,
	.location-permit-list {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
    }

	.location-permit-list {
		flex-direction: column;
		align-items: flex-start;
	}
}