.button-secondary {
    background: var(--clr-secondary);
    color: var(--clr-secondary-text);
    border: 2px solid;

    &:hover,
    &:focus {
        background: var(--clr-secondary-dark);
    }

    &:disabled {
        background: rgba(250, 250, 250, 0.5);
        color: #808080;
        border-color: #808080;
    }
}