.floating-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 200;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #FFF;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: 0 1px 10px rgba($color: #000000, $alpha: 0.15);
}