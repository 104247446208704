.slide-in-panel {
    position: absolute;
    padding: 4.5em 1.5em 2em 1.5em;
    width: 100%;
    left: 0;
    background: var(--clr-bg-body);
    transform: translateX(-100%);
    transition: transform 300ms cubic-bezier(.5, 0, .5, 1);
    z-index: 99;

    .slide-toggle {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        background: white;
        padding: .8em .8em;
        border: 0;
        border-radius: 0 0 5px 0;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, .1);
        cursor: pointer;
        font-size: 1.5rem;
        z-index: 99;
    }

}

@media(max-width: 899.99px) {
    .slide-in-panel {
        &.open {
            box-shadow: 0 4px 10px rgba(0, 0, 0, .3);
            transform: translateX(0);
            position: relative;
        }
    
        &.open .slide-toggle {
            background: var(--clr-bg-body);
            box-shadow: none;
            transform: translateX(0);
        }
    }
}

@media(min-width: 900px) {

    .slide-in-panel {
        &.default {
            position: relative;
            transition: none;
            transform: translateX(0);
            box-shadow: none;
            padding: 0;
        }
    
        &.floating {
            top: 2em;
            left: 1em;
            z-index: 99;
            transition: none;
            transform: translateX(0);
            padding: 3em 1.5em 1.5em;
            box-shadow: 0 0 10px rgba(0, 0, 0, .3);
            border-radius: 8px;
            width: 395px;
        }

        .slide-toggle {
            display: none;
        }
    }
}

@media(min-width: 1600px) {
    .slide-in-panel {
        &.floating {
            top: 3em;
            left: 2em;
            padding: 3.25em 2.25em 1.5em;
            width: 455px;
        }
    }
} 