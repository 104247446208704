.locations {
    display: grid;

    .container {
        display: flex;
        flex-direction: column;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3.5em;
    }

    .item-list {
        display: flex;
        flex-direction: column;
        gap: 2em;
        width: 100%;
        max-width: 800px;
        margin-bottom: 3em;
    }

    .slide-in-panel.open {
        margin-left: -2.5%;
        width: 105%;
    }

    .pagination {
        align-self: end;
    }
}

@media(min-width: 900px) {
    .locations {
        .container {
            flex-direction: row;
            gap: 3em;
        }

        .left {
            width: 300px;
            margin-top: 3.5em;
        }

        .right {
            flex-grow: 1;
        }
    }
}