@media(min-width: 600px) {
    .inline-card {
        flex-direction: row;
        justify-content: space-between;
        height: 192px;

        .card-image {
            width: 30%;
            height: 100%;
            border-radius: 5px 0 0 5px;
        }

        .card-body {
            width: 70%;
            height: 100%;
        }

        .card-title {
            margin-top: .5em;
        }
    }
}