.import-geojson {
    position: absolute;

    .button {
        border: none;
        border-radius: 2px;
        color: #000;
        font-weight: 700;
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    }

    .button:hover,
    .button:focus {
        background-color: rgb(235, 235, 235);
    }

    .import-geojson-file {
        display: none;
    }
}