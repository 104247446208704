.species {
	display: grid;

    .page-title {
        display: none;
    }

    .slide-in-panel.open {
        margin-left: -2.5%;
        width: 105%;
    }

    .item-grid {
        display: grid;
        gap: 2em;
        width: 100%;
        margin: 3em 0;
    }

    .pagination {
        align-self: end;
    }
}

@media(min-width: 600px) {
	.species {
		background: linear-gradient(90deg, var(--clr-bg-body) 60%, rgba(0,0,0, .05) 40%);

        .item-grid {
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        }
	}
}

@media(min-width: 900px) {
	.species {
        .page-title {
            display: block;
        }

        .species-search {
            width: 40%;
        }
    }
}