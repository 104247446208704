.card-image {
    height: 190px;
    width: 100%;
    border-radius: 5px 5px 0 0;
    object-fit: cover;
}
@media(min-width: 600px) {
    .card-image {
        height: 47.5%;
    }
}