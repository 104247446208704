.article {
    padding: 1.5em;
    background-color: #FFF;

	.article-title {
		font-weight: 400;
		margin-top: 0;
	}

	.article-text {
        padding: 0;
		letter-spacing: .5px;
		line-height: 1.5;
		white-space: pre-line;
		a {
			color: var(--clr-primary);
		}
	}
}