.collapsible-article {
    padding: 1.5em;
    background-color: #FFF;

    .collapse-header {
        box-shadow: 0 1px 0 rgba(255, 255, 255, .5);
    }

    .collapse-toggle {
        font-size: 1rem;
        background-color: #FFF;
        color: var(--clr-primary);
    }

    .collapse-toggle:focus,
    .collapse-toggle:hover {
        background: var(--clr-secondary-dark);
    }

    .collapse-label {
        font-size: 1.5rem;
        font-weight: 400;
    }

    .collapsible-paragraph {
		letter-spacing: .5px;
		line-height: 1.5;
    }
}