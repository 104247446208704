:root {
	--clr-primary: #4285f4;
	--clr-primary-dark: #3972ce;
	--clr-primary-disabled: #a3c2fa;
	--clr-secondary: #FAFAFA;
	--clr-secondary-dark: #f1f1f1;
	--clr-secondary-text: #505050;
	--clr-body: #1c1e21;
	--clr-bg-body: #f0f2f5;
	--clr-input-border: #9e9e9e;
	--clr-input-disabled: #f2f2f2;
	--clr-bg-light: #fff;
	--radius-input: 8px;
	--padding-input-x: 1em;
	--padding-input-y: .8em;
	--header-height: 93.8px;
	--footer-height: 92.2px;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: 'Ubuntu', sans-serif;
	font-size: 1.125rem;
	font-weight: 300;
	line-height: 1.4;
	background: var(--clr-bg-body);
	color: var(--clr-body);
}

p {
	margin: 0;
	padding: 0;
	letter-spacing: .5px;
	overflow: hidden;
}

img {
	max-width: 100%;
	display: block;
	object-fit: cover;
}

textarea {
	resize: none;
}

/* typography */

h1 {
	font-size: 2rem;
}

h1,
h2,
h3 {
	line-height: 1;
	margin: 1em 0 1em 0;
}

h3 {
	font-size: 1.5rem;
}

a {
	text-decoration: none;
	color: unset;
}

ul {
	list-style: none;
	padding: 0;
}

.page-title {
	font-size: 2.5rem;
	text-align: center;
}

/* Utility */

.page {
	min-height: calc(100vh - (var(--header-height) + var(--footer-height)));
}

.pull-right {
	margin-left: auto;
}

.center-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.center-text {
	text-align: center;
}

.hidden {
	display: none;
}

.mt-0 {
	margin-top: 0;
}

.mt-1 {
	margin-top: 1em;
}

.mt-2 {
	margin-top: 2em;
}

.mt-3 {
	margin-top: 3em;
}

.mt-4 {
	margin-top: 4em;
}

.mt-5 {
	margin-top: 5em;
}

.mt-6 {
	margin-top: 6em;
}


.mb-0 {
	margin-bottom: 0;
}

.mb-1 {
	margin-bottom: 1em;
}

.mb-2 {
	margin-bottom: 2em;
}

.mb-3 {
	margin-bottom: 3em;
}

.mb-4 {
	margin-bottom: 4em;
}

.mb-5 {
	margin-bottom: 5em;
}

.mb-6 {
	margin-bottom: 6em;
}

/* components */

.container {
	width: 95%;
	margin: 0 auto;
}

.container-flex {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    width: 100%;
}

@media(min-width: 600px) {

	.container-flex {
		flex-direction: row;
	}

	.page-title {
		text-align: left;
		margin: 1.5em 0 1em;
	}

}

@media(min-width: 900px) {
	.container {
		width: 90%;
		max-width: 1500px;
	}
}