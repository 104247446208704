.location-list-item {
    .card-body p {
        max-height: 90px;
    }

    .location-species-list {
        margin-top: 1em;
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
    }
}