.location-info-window {
    max-width: 300px;
    margin-top: 2.75em;
    margin-bottom: .5em;

    .location-info-window-title {
        text-align: center;
        padding-bottom: .5em;
        margin: .5em 0;
        border-bottom: 1px solid var(--clr-primary);
    }

    .location-species-list {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        justify-content: center;
        padding: .25em 0;
    }
}

.gm-style-iw-chr {
    position: absolute;
    right: 0
}