.location-details {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2em;
	width: 95%;
	max-width: 1500px;
	margin: 0 auto;
	padding: 2.5em 0;

	.left, .right {
		display: flex;
		flex-direction: column;
	}

	.right {
		gap: 2em;
	}

	.location-map {
		height: 500px;
	}

	.location-description {
		border-radius: 5px;
		box-shadow: 0 1px 4px rgba(0, 0, 0, .15);
	}
}

@media(min-width: 900px) {
	.location-details {
		flex-direction: row;

		.left {
			width: 35%;
		}

		.right {
			width: 65%;
		}

		.location-map {
			height: 650px;
		}
	}
}