.call-to-action {
    display: block;
    font-size: 1.5rem;
    padding: .7em 1.8em;
    background: #00A361;
    border: 2px solid #00A361;
    color: white;

    &:hover,
    &:focus {
        background: #008b54;
        border-color: #008b54;
    }

    &:active {
        color: #00A361;
        background: white;
    }
}