.landing-page {
    background: #FFF;
    background-image: url('../../assets/images/wave.svg');
    background-size: cover;
    background-position-x: center;

    .landing-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95%;
        max-width: 1350px;
        gap: 5em;
        margin: 0 auto;
        padding: 5em 0;
    }

    .landing-title {
        font-size: 2.5rem;
        font-weight: 700;
        text-align: center;
        line-height: 1.3;
        margin: 0 0 3rem 0;
    }

    .landing-subtitle {
        max-width: 524px;
        font-size: 1.2rem;
        font-weight: 400;
        text-align: center;
        line-height: 1.6;
        letter-spacing: normal;
        padding: 0;
        margin: 0 0 4rem 0;
    }

    .hero-image {
        width: 380px;
    }

    .call-to-action {
        margin: 0 auto;
    }
}

@media(min-width: 1000px) {
    .landing-page {
        .landing-container {
            flex-direction: row;
            justify-content: space-between;
            width: 90%;
            gap: 2em;
            padding: 7em 0;
        }

        .landing-subtitle {
            margin-bottom: 3em;
        }
    }
}

@media(min-width: 1200px) {
    .landing-page {
        .landing-title {
            font-size: 3rem;
        }

        .hero-image {
            width: 450px;
        }
    }
}

@media(min-width: 1350px) {
    .landing-page {

        .landing-title {
            font-size: 3.5rem;
        }

        .hero-image {
            width: 550px;
        }
    }
}