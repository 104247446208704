.permit-list-item {
    flex-direction: row;

    .permit-list-item-image {
        width: 80px;
        height: 80px;
        border-radius: 5px 0 0 5px;
    }

    .permit-list-item-body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        padding: 0 2em;
        overflow: hidden;
    }

    .permit-list-item-title {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.1;
        white-space: nowrap;
        overflow: hidden;
    }
}